<template>
  <div class="login">
    <header-view></header-view>
    <div class="content">
      <div class="box">
        <!-- <div class="login-img">
          <img src="../../assets/images/login.png" alt="" />
        </div> -->
        <div class="title">忘记密码</div>
        <div class="right">
          <el-form
            :model="loginForm"
            label-position="left"
            label-width="100px"
            :rules="loginRules"
            ref="loginForm"
          >
            <el-form-item class="phone" prop="phone" label="手机号">
              <el-input
                placeholder="请输入手机号"
                v-model="loginForm.phone"
              ></el-input>
              <div class="codebtn" v-if="show" @click="getsmsCode">
                获取验证码
              </div>
              <div class="codebtn" v-if="!show">{{ count }}后重发</div>
            </el-form-item>
            <el-form-item prop="smsCode" label="验证码">
              <el-input
                placeholder="请输入短信验证码"
                v-model="loginForm.smsCode"
              ></el-input>
            </el-form-item>
            <el-form-item prop="password" label="登录密码">
              <el-input
                type="password"
                placeholder="请输入登录密码"
                v-model="loginForm.password"
              ></el-input>
            </el-form-item>
            <el-form-item prop="rePassword" label="确认密码">
              <el-input
                type="password"
                placeholder="请输入确认密码"
                v-model="loginForm.rePassword"
              ></el-input>
            </el-form-item>
          </el-form>
          <!-- <div class="zhuce">注册账号</div> -->
          <el-button
            :loading="loading"
            @click.native.prevent="handleLogin"
            class="btn"
            >确定</el-button
          >
        </div>
      </div>
    </div>
    <footer-view></footer-view>
  </div>
</template>

<script>
import HeaderView from "@/components/HeaderView";
import FooterView from "@/components/FooterView";
import { forgetSms, forgetPass } from "@/api/index";
export default {
  components: {
    HeaderView,
    FooterView,
  },
  data() {
    const validateUsername = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请输入手机号"));
      } else {
        callback();
      }
    };
    const validatePass = (rule, value, callback) => {
      if (value.length < 5) {
        callback(new Error("密码不能小于5位"));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        password: "",
        phone: "",
        rePassword: "",
        smsCode: "",
      },
      loginRules: {
        phone: [
          { required: true, trigger: "blur", validator: validateUsername },
        ],
        password: [
          { required: true, trigger: "blur", validator: validatePass },
        ],
        smsCode: [
          { required: true, message: "请输入短信验证码", trigger: "blur" },
        ],
        rePassword: [
          { required: true, message: "请输入确认密码", trigger: "blur" },
        ],
      },
      loading: false,
      timer: null,
      count: "",
      show: true,
    };
  },
  mounted() {
  },
  methods: {
    getsmsCode() {
      if (this.loginForm.phone) {
        forgetSms(this.loginForm.phone)
          .then((res) => {
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.getSmsLogin();
          })
          .catch((err) => {
            // this.loading = false;
          });
      } else {
        this.$message({
          message: "请输入手机号",
          type: "error",
        });
      }
    },
    getSmsLogin() {
      let _this = this;
      const TIME_COUNT = 60;
      if (!_this.timer) {
        _this.count = TIME_COUNT;
        _this.show = false;
        _this.timer = setInterval(() => {
          if (_this.count > 0 && _this.count <= TIME_COUNT) {
            _this.count--;
          } else {
            _this.show = true;
            clearInterval(_this.timer);
            _this.timer = null;
          }
        }, 1000);
      }
    },

    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          forgetPass(this.loginForm)
            .then((res) => {
              this.$router.replace({ path: "/login" });
            })
            .catch((err) => {});
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
.box{
  width: 100% !important;
}
.right{
  width: 100% !important;
  padding:0 10px !important;
}
}
.login {
  background: #f9f9f9;
}
.content {
  min-height: calc(100vh - 100px);
  .box {
    width: 1170px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    padding-top: 20px;
    box-sizing: border-box;
    min-height: calc(100vh - 100px);
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    padding-bottom: 30px;

    .title {
      color: #333;
      font-size: 26px;
      display: flex;
      justify-content: center;
      margin-bottom: 25px;
      letter-spacing: 10px;
    }
    .right {
      width: 50%;
      flex-shrink: 0;
      padding: 0 50px;
      box-sizing: border-box;
      .codebtn {
        border: 1px solid #fe7b0a;
        height: 40px;
        border-radius: 4px;
        margin-left: 16px;
        color: #fe7b0a !important;
        width: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
    .zhuce {
      color: #fff;
      font-size: 16px;
      display: flex;
      justify-content: flex-end;
    }
    .btn {
      width: 100%;
      height: 52px;
      background: #11aa8c;
      color: #fff;
      font-size: 20px;
      margin-top: 20px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
